import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Router, Link } from "@reach/router";
import { motion } from "framer-motion";
import { css } from "@emotion/react";
import Layout from "../layouts/default";
import SEO from "../components/seo";
import { breakpoints } from "../styles/layouts";

import PageHeader from "../components/page-header";
import { Section, Container } from "../styles/layouts";
import { MemberListItem, MemberListPhoto } from "../components/member-list-card";
import { NewMemberList } from "../components/new-members-list";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdBadge, faList } from '@fortawesome/pro-light-svg-icons';

library.add(faList, faIdBadge);

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1
    }
  }
}

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1
  }
}

const MemberListDefault = ({ members }) => {

  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="visible"
      css={MembersListStyle}>
      {members.map(({ node }) => {
        return (
          <motion.div key={node.slug} className="list-item" variants={item}>
            <MemberListItem member={node} />
          </motion.div>
        );
      })}
      { !members.length && <p>Not found.</p> }
    </motion.div>
  );
}

const MemberListPhotos = ({ members }) => {
  return (
    <motion.div
      variants={container}
      initial="hidden"
      animate="visible"
      css={MembersListPhotosStyle}>
      {members.map(({ node }) => {
        return (
          <motion.div key={node.slug} className="list-item" variants={item}>
            <MemberListPhoto member={node} />
          </motion.div>
        );
      })}
      { !members.length && <motion.div>Not found.</motion.div> }
    </motion.div>
  );
}

const MembersListPhotosStyle = css`
  position: relative;
  padding: 1rem 0 0 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2.5rem;
  row-gap: 2.5rem;

  &:before {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    right: 0;
    top: -.5rem;

    height: 1px;

    background: rgba(0,0,0,.05);
  }

  @media ${breakpoints.md} {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }

  @media ${breakpoints.sm} {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
    row-gap: 2rem;
  }

  @media ${breakpoints.xs} {
    grid-template-columns: repeat(1, 1fr);
  }

  .list-item {

  }
`;

const ViewModeLink = (props) => {

  const isActive = (props) => {
    return props.isCurrent ? { 'data-is-active': true } : { 'data-is-active': false }
  }

  return (
    <Link
      getProps={isActive}
      {...props}
    >{props.children}</Link>
  );
}

const MembersPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMembersJson(sort: {fields: index, order: ASC}) {
        edges {
          node {
            index
            slug
            profile {
              name {
                full
              }
              title
            }
            nickname
            role
            achievement {
              point
              latest_achievement {
                title
                url
                date
              }
            }
            minion {
              minion_count
            }
            mount {
              mount_count
            }
            current_job {
              key
              name
              level
              type
            }
            contents {
              levels {
                level
                name
                type
                exp {
                  current
                  next
                }
              }
            }
            join_date
            lodestone_id
            lodestone_url
            room
            updateAt
            images {
              face
              body
            }
          }
        }
      }
    }
  `);

  const [members, setMembers] = useState(data.allMembersJson.edges);
  const [searchValue, setSearchValue] = useState('');

  const handleChange = (e) => {
    let result_members = [];

    if(!e.target.value) {
      setSearchValue('');
      setMembers(data.allMembersJson.edges);
      return false;
    }

    setSearchValue(e.target.value);

    result_members = data.allMembersJson.edges.filter(member => {
      let searchText = searchValue.toLowerCase();
      let name = member.node.profile.name.full.toLowerCase();
      return name.includes(searchText);
    });

    if(searchValue && result_members) {
      setMembers(result_members);
    }
  }

  return (
    <Layout>
      <SEO title="メンバー" description="FC Assort に所属するメンバーの一覧です。" />
      <main>
        <PageHeader title="Members">
          <div css={MembersFilterStyle}>
            <input
              className="filter-input"
              type="text"
              placeholder="Search charactor name..."
              value={searchValue}
              onKeyUp={handleChange}
              onChange={handleChange} />
            <p className="filter-length">Result: <strong>{members.length}</strong></p>
          </div>
        </PageHeader>
        <Section>
          <Container>
            <NewMemberList css={css` margin-bottom: 1.5rem; `} />
            <ul css={ViewModeNav}>
              <li className="nav-item">
                <ViewModeLink to="/members" className="item-pointer">
                  <FontAwesomeIcon className="item-icon" icon={["fal", "list"]} title="List" />
                </ViewModeLink>
              </li>
              <li className="nav-item">
                <ViewModeLink to="/members/photo" className="item-pointer">
                  <FontAwesomeIcon className="item-icon" icon={["fal", "id-badge"]} title="Photo" />
                </ViewModeLink>
              </li>
            </ul>
            <Router basepath={`/members`}>
                <MemberListDefault members={members} path="/" />
                <MemberListPhotos members={members} path="/photo" />
              </Router>
          </Container>
        </Section>
      </main>
    </Layout>
  );
}

export default MembersPage;

const ViewModeNav = css `
  margin: 0 0 1rem auto;
  width: 4rem;
  padding-inline-start: 0;

  list-style: none;

  .nav-item {
    display: inline-block;
  }

  .item-pointer {
    padding: .5rem;

    line-height: 1rem;
    color: #ACAEAF;

    &[data-is-active="true"] {
      color: #1D1E1F;
    }
  }

  .item-icon {
    width: 1rem;
    height: 1rem;
  }
`;

const MembersFilterStyle = css`
  position: relative;
  margin-block-start: 1rem;

  .filter-input {
    transition: background .2s ease-in-out;
    display: inline-block;
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
    line-height: 1.5rem;
    font-size: 1rem;

    border-radius: .4rem;
    border: none;

    background: rgba(255,255,255,.2);

    &:focus {
      background: rgba(255,255,255,1);
    }

    &::placeholder {
      color: #ddd;
    }
  }

  .filter-length {
    position: absolute;
    right: 0;

    color: #eee;
    line-height: 1rem;
    text-align: right;
  }
`;



const MembersListStyle = css`
  position: relative;
  padding: 0;

  &:before {
    position: absolute;
    display: block;
    content: "";
    left: 0;
    right: 0;
    top: -.5rem;

    height: 1px;

    background: rgba(0,0,0,.05);
  }

  .list-item {
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:after {
      position: absolute;
      display: block;
      content: "";
      left: 5rem;
      right: 0;
      bottom: -.5rem;

      height: 1px;

      background: rgba(0,0,0,.05);

      @media ${breakpoints.xs} {
        left: 0;
      }
    }

    &:last-of-type {
      margin-bottom: 0;

      &:after {
        display: none;
      }
    }
  }
`;
