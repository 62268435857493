import React from "react";
import { DateTime } from "luxon";
import { Link } from "gatsby";

import { css } from "@emotion/react";
import { breakpoints } from "../styles/layouts";

import CharaFaceImg from "./chara-face";
import CharaBodyImg from "./chara-body";
import { JobBadge } from "./job-icon";
import { NewBadge } from "./badge";
import LayerIndex from "../utils/layer-index";

import ResistanceIcon from '../images/icons/function/resistance.inline.svg';
import ElementalIcon from '../images/icons/function/elemental.inline.svg';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBurn, faTrophy, faHorseSaddle, faDove, faHomeLg, faFlagAlt} from '@fortawesome/pro-light-svg-icons';

library.add(faBurn, faTrophy, faHorseSaddle, faDove, faHomeLg, faFlagAlt);

const LatestAchievement = ({ achievement }) => {
  const now = DateTime.local();
  const achievementDate = DateTime.fromISO(achievement.date);
  const isNewest = (achievementDate.plus({ days: 3 }) > now);

  return (
    <div className="latest-achievement">
      <FontAwesomeIcon className="icon" title="個室番号" icon={["fal", "flag-alt"]} />
      { isNewest && <NewBadge top={0} right={0} /> }
      {achievement.title}
    </div>
  );
}

export const MemberListPhoto = ({ member }) => {
  return (
    <div css={MemberListPhotoStyle}>
      <Link to={`/members/${member.slug}`} className="card-pointer">
        <div className="chara-header">
          <div className="chara-role">{member.role}</div>
          <h2 className="chara-name">{member.profile.name.full}</h2>
        </div>
        <div className="chara-photo">
          { member.current_job && <JobBadge
            className="chara-current-job"
            data-job-type={member.current_job.type}
            slug={member.current_job.key}
            title={member.current_job.name}
            label={member.current_job.level} /> }
          <CharaBodyImg filename={member.images.body} width={320} className="photo-image" />
        </div>
      </Link>
    </div>
  );
}

const MemberListPhotoStyle = css`
  position: relative;

  .card-pointer {
    position: relative;

    color: #6D6F70;

    &:hover {
      background: rgba(0,0,0,.05);
      text-decoration: none;
    }

    &:active {
      background: rgba(0,0,0,.1);
      text-decoration: none;
    }
  }

  .chara-header {
    margin-bottom: 1.5rem;
  }

  .chara-role {
    color: #CC244F;
    font-weight: bold;
  }

  .chara-name {
    margin: 0;
    padding: 0;
    display: block;

    color: #1D1E1F;
  }

  .chara-current-job {
    position: absolute;
    left: -.25rem;
    top: -.25rem;
    z-index: ${LayerIndex.overlay};
  }

  .chara-photo {
    position: relative;
    transition: transform .2s ease-in-out;

    .photo-image {
      display: block;
      width: 100%;
      height: auto;
    }

    &:hover {
      transform: scale(1.05);
      box-shadow: 0px 32px 32px -32px rgba(0, 0, 0, 0.24), 0px 8px 64px -16px rgba(0, 0, 0, 0.12), 0px 12px 92px -24px rgba(0, 0, 0, 0.06);
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;

      border-radius: 12px;
      border: 1px solid rgba(0,0,0,.05);
    }
  }
`;

export const MemberListItem = ({ member }) => {

  const Resistance = member.contents.levels.find(content => content.name == "レジスタンスランク");

  return (
    <div css={MembersCardStyle}>
      <Link to={`/members/${member.slug}`} className="card-pointer">
        <div className="chara-icon">
          { member.current_job && <JobBadge
            className="chara-current-job"
            data-job-type={member.current_job.type}
            slug={member.current_job.key}
            title={member.current_job.name}
            label={member.current_job.level} /> }
          <CharaFaceImg filename={member.images.face} className="chara-image" width={64} height={64} />
        </div>
        <div className="chara-main">
          <div className="chara-role">{member.role}</div>
          <h2 className="chara-name">{member.profile.name.full}</h2>
          <div className="chara-nickname">{member.nickname}</div>
        </div>
        <div className="chara-sub">
          { !!member.achievement.latest_achievement.length && <LatestAchievement achievement={member.achievement.latest_achievement[0]} /> }
          <ul className="chara-statuses">
            <li className="status-item">
              <FontAwesomeIcon className="icon" title="アチーブメントポイント" icon={["fal", "trophy"]} />
              <span> { !!member.achievement.point && parseInt(member.achievement.point).toLocaleString() || '-' }</span>
            </li>
            <li className="status-item">
              <FontAwesomeIcon className="icon" title="個室番号" icon={["fal", "home-lg"]} />
              <span>
                { !!member.room && member.room.toString().padStart(3, "0") }
                { !member.room && '-' }
              </span>
            </li>
            <li className="status-item">
              <ResistanceIcon className="custom-icon" title="レジスタンスランク" />
              <span>
                { !!Resistance && Resistance.level}
                { !Resistance && '-' }
              </span>
            </li>
          </ul>
          <div className="chara-join">
            <span className="join-days-elapsed">{
              Math.floor(Math.abs(DateTime.fromISO(member.join_date).diffNow('days').toObject().days)).toLocaleString()
            } days</span>
            {" / "}
            <time className="join-date" dateTime={member.join_date}>Join: {DateTime.fromISO(member.join_date).toFormat("yyyy-MM-dd")}</time>
          </div>
        </div>
      </Link>
    </div>
  );
}

const MembersCardStyle = css`
  position: relative;
  margin: 0 -1.5rem;

  @media ${breakpoints.xs} {
    margin: 0 -1rem;
  }

  .card-pointer {
    transition: background-color .1s ease-in-out;
    position: relative;
    display: flex;
    align-items: center;
    padding: 1.5rem;
    box-sizing: border-box;

    @media ${breakpoints.xs} {
      flex-wrap: wrap;
      padding: 1rem;
    }

    color: #6D6F70;

    border-radius: .4rem;
    background: transparent;

    &:hover {
      background: rgba(0,0,0,.05);
      text-decoration: none;
    }

    &:active {
      background: rgba(0,0,0,.1);
      text-decoration: none;
    }
  }

  .chara-icon {
    position: relative;
    margin-right: 1.5rem;
    width: 4rem;
    height: 4rem;

    @media ${breakpoints.xs} {
      margin-right: 1rem;
    }
  }

  .chara-main {
    flex-basis: 30%;
    margin-right: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media ${breakpoints.xs} {
      margin-right: 0;
      flex-basis: calc(100% - 5.5rem);
    }
  }

  .chara-sub {
    position: relative;
    overflow: hidden;
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    font-size: .85rem;

    @media ${breakpoints.xs} {
      margin-top: 1rem;
      flex-basis: 100%;
    }
  }

  .chara-statuses {
    list-style: none;
    margin-block-start: .5rem;
    margin-block-end: 0;
    padding-inline-end: 0;
    padding-inline-start: 0;
    display: flex;

    .status-item {
      min-width: 3ch;
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .icon {
      margin-right: .25em;
      width: 1em;
      height: 1em;
      color: #ACAEAF;
    }

    .custom-icon {
      margin-right: .25em;
      margin-bottom: -.25em;
      width: 1rem;
      height: 1rem;
      color: #ACAEAF;
    }
  }

  .latest-achievement {
    position: relative;
    overflow: hidden;

    font-size: .85rem;
    white-space: nowrap;
    text-overflow: ellipsis;

    color: #4D4F50;

    .icon {
      margin-right: .5em;
      width: 1em;
      height: 1em;
      color: #ACAEAF;
    }
  }

  .chara-current-job {
    position: absolute;
    top: -.25rem;
    left: -.25rem;
  }

  .chara-role {
    color: #CC244F;
    font-weight: bold;
  }

  .chara-name {
    margin: 0 0 .5em 0;
    padding: 0;
    display: block;

    color: #1D1E1F;
  }

  .chara-nickname {

  }

  .chara-join {
    margin-top: .5rem;

    color: #ACAEAF;
    font-size: .75rem;

    .join-date {
      display: inline-block;
    }

    .join-days-elapsed {
      display: inline-block;
    }
  }
`;
